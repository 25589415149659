<template>
  <module
    ref="module"
    id="marketplace"
    :titleIcon="dynamicIcon"
    :title="dynamicTitle"
    :use-default-list="false"
  >

    <template slot="toolbar-buttons-slot">
      <p-button v-if="!adminusr && isStrategies" :type="!isStrategies ? 'info' : 'success'" @click="onChange">
        <i class="fa fa-star"></i> {{ $t('watchlist') }}
      </p-button>
      <p-button v-else-if="!adminusr && !isStrategies" type="info" @click="onChange">
        <i class="nc-icon nc-sound-wave"></i> {{ $t('strategytxt') }}
      </p-button>
      <p-button class="filter-button" @click="openDrawer">
        <i class="fa fa-search"></i>
      </p-button>
    </template>

    <template slot="global">
      <div class="marketplace-header">

        <p-button v-if="!adminusr && !isStrategies && strategies.length > 0" :type="!isStrategies ? 'info' : 'success'" @click="onRemoveAllWatchlist">
          <i class="fa fa-trash"></i> {{this.$t("remove_everyone_from_watchlist")}}
        </p-button>

        <marketplace-follow-strategy/>

      </div>
      <div class="marketplace-footer">
        <div v-if="!loading && strategies.length === 0" class="text-center margin-top">
          <h2 v-if="!isStrategies && !strategiesNotFound">{{ $t('nostrategies') }}</h2>
          <h2 v-else-if="strategiesNotFound">{{ $t('nostrategiesfound') }}</h2>
        </div>
        <el-row v-else-if="!strategiesNotFound" :gutter="20" class="marketplace-card">
          <el-col
            v-for="(strategy, index) in getStrategies"
            :key="index"
            :span="100"
            :style="getDrawerSize"
          >
            <marketplace-card
              class="margin-top"
              :card="strategy"
              :chart-url="getTaskerUrl() + '/storage/' + strategy.strat_id + '_linechart.svg'"
              :strats-in-whitelabel="strategiesInWhitelabel"
              :show-avatar-placeholder="showAvatarPlaceholder"/>
          </el-col>
        </el-row>
        <div v-if="loading" class="text-center">
          <i class="fa fa-cog fa-spin fa-huge progress-style"></i>
        </div>
      </div>

     <marketplace-adavanced-filter @on-apply-filter="applyFilters"/>

   </template>

  </module>
</template>

<script>

import Vue from 'vue';
import {Tabs, TabPane } from 'src/components/UIComponents'
import MarketplaceCard from "@/components/Dashboard/Views/Dashboard/Marketplace/MarketplaceCard";
import MarketplaceFollowStrategy from "./MarketplaceFollowStrategy"
import Constants from 'src/assets/constants'

import {mapActions, mapState, mapGetters} from "vuex";

import {
  InfiniteScroll,
  Container,
  Tooltip,
  Button,
  Aside,
  Main,
  Col,
  Row,
} from 'element-ui'

import MarketplaceAdavancedFilter
  from "@/components/Dashboard/Views/Dashboard/Marketplace/MarketplaceAdavancedFilter.vue";
import swal from "sweetalert2";

const rules = Constants.methods.getRules();

const user_data = JSON.parse(localStorage.getItem('user-info'));

Vue.use(Col);
Vue.use(Row);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Container);
Vue.use(Main);
Vue.use(Aside);
Vue.use(InfiniteScroll);

export default {
  name: "Marketplace",
  components: {
    MarketplaceAdavancedFilter,
    Tabs,
    TabPane,
    MarketplaceCard,
    MarketplaceFollowStrategy,
  },
  computed: {
    ...mapState('marketplace', ['strategies', 'isStrategies', 'strategiesInWhitelabel']),
    ...mapGetters('marketplace', ['getStrategies']),
    dynamicTitle() {
        if (!this.isStrategies) {
          return this.$t('watchlist')
        } else {
          return this.$t('marketplace')
        }
    },
    dynamicIcon() {
        if (!this.isStrategies) {
          return "fa fa-star"
        } else {
          return "fa fa-shopping-bag"
        }
    },
    getDrawerSize() {
      if (this.windowSize <= 600) {
        return 'width: 100%'
      } else if (this.windowSize >= 600 && this.windowSize < 1280) {
        return 'width:' + (100 / 2) + '%'
      } else if (this.windowSize >= 1280 && this.windowSize <= 1920) {
        return 'width:' + (100 / 3) + '%'
      } else if (this.windowSize > 1920 && this.windowSize < 2370) {
        return 'width:' + (100 / 5) + '%'
      }  else {
        return 'width:' + (100 / 6) + '%'
      }
    },
  },
  data() {
    return {
      adminusr: rules.isAdmin,
      loading: false,
      strategiesNotFound: false,
      next_page:0,
      more_pages:false,
      tmpStrategies: [],
      dataSearchFilter: {},
      windowSize: window.innerWidth,
      showAvatarPlaceholder: 0
    }
  },
  beforeMount() {
    // Uncomment this after launch marketplacev2 and improvements
    //this.loadStrategiesInWhitelabel()
    this.loadStrategies()
    this.loadUserInfo()
  },
  mounted() {
    window.addEventListener('resize', e => {
      this.windowSize = window.innerWidth;
      this.handleScroll()
    });
    window.onscroll = () => {
      this.handleScroll()
    }
  },
  methods: {
    ...mapActions('marketplace', ['setIsStrategy', 'setStrategies', 'showFilterDrawer', 'setStrategiesInWhitelabel']),
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    isWlAdmin() {
      return Constants.methods.getRules().hasWlRole
    },
    isWlSession() {
      return Constants.methods.getRules().isWhitelabelSession
    },
    getTaskerUrl() {
      return Constants.methods.taskerurl()
    },
    prevPage() {
      window.history.back()
    },
    onChange() {
      this.setIsStrategy(!this.isStrategies);
      this.tmpStrategies = []
      this.next_page = 0
      if (!this.isStrategies) {
        this.loadWatchlist()
      } else {
        this.loadStrategies()
      }
    },
    onRemoveAllWatchlist() {
      swal({
        title: this.$t("confirm"),
        text: this.$t("remove_from_wathclist_title"),
        type: 'warning',
        showCancelButton: true,
      }).then(this.delInsertWatchlist)
    },
    delInsertWatchlist() {

      this.loading = true

      this.setStrategies([])

      this.$delAllStrategy({}, {userid: user_data.email}).then(
          (resp) => {
              if (resp.success) {
                this.loadWatchlist(false, this.dataSearchFilter)
                this.loading = false
              }
          }
        )

    },
    applyFilters(data) {
      this.next_page = 0
      this.dataSearchFilter = data
      if (!this.isStrategies) {
        this.loadWatchlist(false, data)
      } else {
        this.loadStrategies(false, data)
      }
    },
    loadStrategiesInWhitelabel() {
      if (this.isWlSession()) {
        this.$getWhitelabelSettings()
          .then(this.callBackWhitelabelSettings, this.handler)

        if (this.isWlAdmin()) {
          const sucess = resp => {
            resp.data.strategies.forEach(el => {
              this.setStrategiesInWhitelabel(el)
            });
          }
          const fail = resp => {
            console.log(resp)
          }
          this.$retrieve_whitelabels(Constants.methods.getRules().whitelabel).then(sucess, fail)
        }

      }
    },
    loadStrategies(isPagination = false, data) {

      this.loading = true
      this.strategiesNotFound = false

      if (!isPagination) {
        this.more_pages = true
        this.next_page = 0
        this.setStrategies([])
      }

      this.$getMarketplace_V2({
        next_page: this.next_page,
        data: data
      }).then(
        (resp) => {
          this.responseHandler(resp)
        },
        (e) => {
          this.$toast.clear()
          this.$toast.error(e.statusText)
          this.loading = false
        }
      )
    },
    loadWatchlist(isPagination = false, data) {

      this.loading = true
      this.strategiesNotFound = false

      if (!isPagination) {
        this.more_pages = true
        this.next_page = 0
        this.setStrategies([])
      }

      this.$getWatchlist_v2({
        next_page: this.next_page,
        data: data
      }).then(
        (resp) => {
          this.responseHandler(resp)
        },
        (e) => {
          this.$toast.clear()
          this.$toast.error(e.statusText)
          this.loading = false
        }
      )
    },
    responseHandler(resp) {
      if (resp.success) {
        this.strategies.push(...resp.data[0].strategies.map(strat => ({
          ...strat,
          svg_error: false,
        })))
        this.next_page = resp.data[0].next_page
        this.more_pages = resp.data[0].more_pages
        this.loading = false
      } else {
        this.responseError(resp)
      }
    },
    responseError(error) {
      if (error.msg !== undefined && error.msg === 'notfound') {
        this.strategiesNotFound = true
      }
      this.loading = false
    },
    handleScroll() {
      let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      let documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight);

      let scrollPercentage = (scrollPosition + windowHeight) / documentHeight;

      let isAtBottom = (scrollPercentage >= 0.95) || (scrollPosition + windowHeight === documentHeight);

      if (isAtBottom && !this.loading) {

        if (this.more_pages) {
          this.loading = true
        }

        if (this.more_pages) {
          if (this.isStrategies) {
            this.loadStrategies(true, this.dataSearchFilter)
          } else {
            this.loadWatchlist(true, this.dataSearchFilter)
          }
        }
      }
    },
    loadUserInfo() {
      this.$getUserInfo(user_data.email).then((resp) => {
        this.showAvatarPlaceholder = resp.data[0].show_avatar_placeholder
      }, function(err) {
        console.log(err);
      });
    },
    openDrawer() {
      this.showFilterDrawer()
    },
  }
}
</script>

<style scoped>
</style>
