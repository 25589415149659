<template>
  <el-drawer
      :title="txt.advancedFilter"
      :visible.sync="openFilter"
      :before-close="handleClose"
      :size="getMenuDrawerSize"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      class="marketplace-drawer"
      >
      <div>
        <el-row class="fixed-header">
          <el-col class="padding">
            <p-button type="info" @click="doApplyFilter" :loading="loading" round>
              <i class="fa fa-filter"></i>
              {{$t('applyfilter')}}
            </p-button>
            <p-button type="danger" @click="clearFields" round><i class="fa fa-trash"></i></p-button>
          </el-col>
        </el-row>
      </div>
      <div class="padding">
        <el-row>
          <el-col>
            <el-form style="padding-top: 65px;" :model="formFilter" label-position="top">
              <el-divider></el-divider>
              <h5>{{txt.filterBy}}</h5>
              <el-form-item :label="txt.perStratName">
                <fg-input v-model="formFilter.perStratName"></fg-input>
              </el-form-item>
              <el-form-item :label="txt.perTradeName">
                <fg-input v-model="formFilter.perName"></fg-input>
              </el-form-item>
              <el-form-item :label="txt.perTradeEmail">
                <fg-input v-model="formFilter.perTraderEmail" autocomplete="off"></fg-input>
              </el-form-item>
              <el-form-item :label="txt.perTradeNickname">
                <fg-input v-model="formFilter.perTraderNickname" autocomplete="off"></fg-input>
              </el-form-item>
            </el-form>
            <el-form :model="formFilter" label-position="left">
              <el-form-item :label="txt.perProfitableOnly">
                <el-switch style="float: right; margin-top: 10px;" v-model="formFilter.perProfitableOnly"></el-switch>
              </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-form :model="formFilter" label-position="left">
              <h5>{{txt.sorting}}</h5>
              <el-form-item :label="txt.typeBy" :label-width="getDrawerLabelsSize">
                <el-select style="float: right" v-model="formFilter.typeBy" @change="onTypeChange">
                  <el-option
                    v-for="s in formSelectType"
                    :selected="s.key === 'per_old'"
                    :key="s.key"
                    :label="s.value"
                    :value="s.key">
                    <i :class="s.icon" aria-hidden="true"></i> {{s.value}}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="txt.sortBy" :label-width="getDrawerLabelsSize">
                <el-select style="float: right" v-model="formFilter.sortBy">
                  <el-option
                    v-for="s in formSelectSortable"
                    :selected="s.key == 'asc'"
                    :key="s.key"
                    :label="s.value"
                    :value="s.key"
                    :icon="s.icon">
                    <i :class="s.icon" aria-hidden="true"></i> {{s.value}}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
</template>
<script>

import {mapState} from "vuex";
import Vue from 'vue';
import {
  Form,
  FormItem,
  Input,
  Checkbox,
  Switch,
  Drawer,
  Divider,
  Col,
  Row,
} from 'element-ui';

Vue.use(Col);
Vue.use(Row);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Divider);

export default {
  name: "MarketplaceAdvancedFilter",
  computed: {
    ...mapState('marketplace', ['openFilterDrawer']),
    getDrawerLabelsSize() {
      if (this.windowSize >= 1920) {
        return '60%'
      } else  if (this.windowSize > 1280 && this.windowSize < 1920) {
        return '25%'
      } else if (this.windowSize >= 1000 && this.windowSize <= 1280) {
        return '35%'
      } else if (this.windowSize > 600 && this.windowSize < 1000) {
        return '45%'
      } else {
        return '55%'
      }
    },
    getMenuDrawerSize() {
      if (this.windowSize >= 1920) {
        return '20%'
      } else  if (this.windowSize > 1280 && this.windowSize < 1920) {
        return '35%'
      } else if (this.windowSize >= 1000 && this.windowSize <= 1280) {
        return '45%'
      } else if (this.windowSize > 600 && this.windowSize < 1000) {
        return '55%'
      } else {
        return '100%'
      }
    },
  },
  data () {
    return {
      loading: false,
      openFilter: false,
      closingDrawer: false,
      windowSize: window.innerWidth,
      txt: {
        perStratName: this.$t('per_strategy_name'),
        perTradeNickname: this.$t('per_trade_nickname_txt'),
        perTradeEmail: this.$t('per_trade_email'),
        perTradeName: this.$t('per_trade_name_txt'),
        perProfitableOnly: this.$t('per_profitable_only'),
        advancedFilter: this.$t('advanced_filter_txt'),
        sorting: this.$t('sorting_text'),
        sortBy: this.$t('sortby'),
        typeBy: this.$t('type_by_text'),
        filterBy: this.$t('filter_by'),
      },
      formFilter: {
        perProfitableOnly: true,
        hotRightNow: false,
        perStratName: '',
        perName: '',
        perTraderEmail: '',
        perTraderNickname: '',
        sortBy: 'desc',
        typeBy: 'per_old',
      },
      formSelectSortable: [
        {
          key: 'asc',
          value: 'Asc',
          icon: 'fa fa-sort-amount-asc',
        },
        {
          key: 'desc',
          value: 'Desc',
          icon: 'fa fa-sort-amount-desc',
        },
      ],
      formSelectType: [
        {
          key: 'per_profit',
          value: this.$t('per_profit_txt')
        },
        {
          key: 'per_old',
          value: this.$t('per_old_txt')
        },
        {
          key: 'per_risk_level',
          value: this.$t('per_risk_level_txt')
        },
        {
          key: 'per_number_of_likes',
          value: this.$t('per_number_of_likes')
        },
      ],
    }
  },
  methods: {
    handleClose(done) {
      if (this.loading) {
        return;
      }
      if (this.closingDrawer) {
        this.loading = true;
        this.$emit('on-apply-filter', this.formFilter)
        setTimeout(() => {
          // animation takes time
          setTimeout(() => {
            this.loading = false;
            this.cancelForm()
            done()
          }, 400);
        }, 400);

      } else {
        this.cancelForm()
      }
    },
    onTypeChange(e) {
      if (e === 'per_risk_level') {
        this.formFilter.sortBy = 'asc'
      } else {
        this.formFilter.sortBy = 'desc'
      }
    },
    cancelForm() {
      this.openFilter = false
      this.closingDrawer = false
    },
    doApplyFilter() {
      this.closingDrawer = true
      this.$refs.drawer.closeDrawer()
    },
    clearFields() {
      this.formFilter = {
        perProfitableOnly: true,
        sortBy: 'desc',
        typeBy: 'per_old',
      }
    }
  },
  mounted() {
    window.addEventListener('resize', e => {
      this.windowSize = window.innerWidth;
    });
  },
  watch: {
    openFilterDrawer: {
      handler(val) {
        this.openFilter = val
      }
    },
    deep: true,
  }
}

</script>
