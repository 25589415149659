<template>
  <div>
    <el-tooltip :content="txt.tooltipContent" :open-delay="100" placement="top" popper-class="adjust-width down-bit"
                style="padding-right: 5px;">
      <i class="nc-icon nc-alert-circle-i"></i>
    </el-tooltip>
    <p-switch
      v-model="include"
      :onText="txt.yes"
      :offText="txt.no"
      @input="triggerToggle()">
    </p-switch>
  </div>
</template>

<script>
import PSwitch from "@/components/UIComponents/PSwitch";
import {Tooltip} from 'element-ui';
import CONSTANTS from "@/assets/constants";

const rules = CONSTANTS.methods.getRules();

export default {
  name: "wl-marketplace-switch",
  props: {
    sid: {
      type: Number,
      description: 'Strategy Id'
    },
    strategiesInWhitelabel: {
      type: Array,
      description: 'Strategies already in whitelabel'
    },
  },
  components: {
    [Tooltip.name]: Tooltip,
    PSwitch
  },
  data() {
    return {
      txt: {
        yes: this.$t('yestxt'),
        no: this.$t('notxt'),
        tooltipContent: this.$t('tooltipWlMarketplace'),
      },
      include: false,
    }
  },
  methods: {
    strategyAdded(resp) {
      console.log(resp)
      this.$toast.success(this.$t('strategyAddedToWl'));
    },
    strategyRemoved(resp) {
      console.log(resp)
      this.$toast.success(this.$t('strategyRemovedFromWL'));
    },
    errorOp(resp) {
      this.$toast.warning(this.$t('somethingwentwrong'));
    },
    triggerToggle() {
      const param = {
        wlId: rules.whitelabel,
        stratId: this.sid,
      }

      if (this.include) {
        this.addStrategyWhitelabel(param).then(this.strategyAdded, this.errorOp)
      } else {
        this.removeStrategyWhitelabel(param).then(this.strategyRemoved, this.errorOp)
      }
    }
  },
  mounted() {
     this.strategiesInWhitelabel.forEach( el => {
          if ( el === this.sid ) {
            this.include = true
          }
       }
     )
  },
}
</script>

<style scoped>

</style>
