<template>
  <el-row class="container" :gutter="20" align="middle" style="max-height: 400px; overflow-x: hidden;">
    <div class="row">
      <div class="col-md-6" v-for="acc in accounts">
        <div class="broker-logo">
          <p-checkbox @input="addAccountToFollow(acc)" v-model="acc.selected">
            <el-avatar class="avatar" :size="60" :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`"/>
          </p-checkbox>
        </div>
        <div>
          <span v-if="acc.nickname == acc.account || !acc.nickname" class="font-weight-normal" style="color: black; font-size: 20px">
              {{ acc.account }}
          </span>
          <span v-else class="font-weight-normal" style="color: black; font-size: 20px">
              {{ acc.nickname }}  <small class="category">({{acc.account}})</small>
          </span>
          <br>
          <span class="text-muted" v-if="acc.status.balance">
            <b>{{ txt.balance }}:</b>
            <b class="text-success">
              {{ acc.status.balance.toFixed(2) }}
            </b>
          </span>
          <span class="text-muted" v-if="acc.status.equity">
            <b>{{ txt.equity }}:</b>
            <b :class="acc.equity < acc.balance ? 'text-danger' : 'text-success'">
              {{ acc.status.equity.toFixed(2) }}
            </b>
          </span>
          <br>
          <p></p>
          <hr>
        </div>
      </div>
    </div>
  </el-row>
</template>

<script>

import Vue from 'vue';
import { Col, Row } from 'element-ui'
import {mapActions, mapState} from "vuex";
import swal from "sweetalert2";

Vue.use(Col);
Vue.use(Row);

export default {
  name: "MarketplaceFollowStrategyAccounts",
  props: {
    txt: {
      type: Object,
    },
  },
  computed: {
    ...mapState('marketplaceFollowStrategy', ['accounts', 'accountsToFollow']),
  },
  methods: {
    ...mapActions('marketplaceFollowStrategy', ['setAccountToFollow']),
    addAccountToFollow(acc) {

      if (!this.accountsToFollow.find(el => el.id == acc.id)) {
        if(acc.balance < acc.minimum_balance) {
          swal({
            title: this.$t('attentiontxt'),
            text: this.$t('STRATEGY_LIMIT_BIGGER_ACCOUNT_BALANCE', [acc.minimum_balance]),
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          }).then(
            value => {
              this.setAccountToFollow(acc)
            },
            dismiss => {
              acc.selected = false
            }
          ).catch(swal.noop);
        } else {
          this.setAccountToFollow(acc)
        }
      } else {
        this.setAccountToFollow(acc)
      }

    }
  }
}
</script>
