<template>
  <el-row class="no-margin">
    <el-col :xl="24">
      <div class="custom-title">
        <span>{{title}}</span><br />
      </div>
      <el-slider class="thermometer" v-model="sliderValue" :max="maxSize" :step="maxSize" :show-tooltip="showTooltip || false" disabled/>
    </el-col>
  </el-row>
</template>

<script>

import Vue from 'vue';
import { Slider, Tag } from 'element-ui'

Vue.use(Slider)
Vue.use(Tag)

export default {
  data() {
    return {
      sliderValue: 0.0,
    }
  },
  methods: {
    setSliderVal(v) {
      this.sliderValue = v
    }
  },
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
    },
    maxSize: {
      type: Number,
    },
    showTooltip: {
      type: Boolean,
    },
  },
  created() {
    if (this.value === undefined || this.value === 'None') {
      this.setSliderVal(0.5)
    } else {
      if (this.value.toLowerCase() === 'low') {
        this.setSliderVal(1)
      } else if (this.value.toLowerCase() === 'medium') {
        this.setSliderVal(5)
      } else if (this.value.toLowerCase() === 'high') {
        this.setSliderVal(7)
      } else if (this.value.toLowerCase() === 'very high') {
        this.setSliderVal(9)
      }
    }

  },
}

</script>
