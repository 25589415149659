<template>
  <div class="col-md-12">
    <div class="row text-justify">
      <div class="alert" :class="changeTermAgree ? 'alert-success' : 'alert-info'">
        <p style="font-size: 18px" v-html="txt.byfollowingterms">
        </p>
        <p style="font-size: 18px" class="text-center">
          {{ txt.pricingterms }}
        </p>
      </div>
      <br>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="lead text-center">
          <p-checkbox v-model="changeTerm" class="label-check">
            {{ txt.iagreetxt }}
          </p-checkbox>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "MarketplaceFollowStrategyTerms",
  computed: {
    ...mapState('marketplaceFollowStrategy', ['changeTermAgree'])
  },
  props: {
    txt: {
      type: Object,
    },
  },
  data() {
    return {
      changeTerm: false,
    }
  },
  methods: {
    ...mapActions('marketplaceFollowStrategy', ['setTerms'])
  },
  watch: {
    changeTerm: {
      handler(val) {
        this.setTerms(val)
      },
      deep: true
    },
  }
}
</script>
