<template>
  <modal id="showAccModal"
         class="marketplace-follow-strategy"
         :show.sync="showAccModal"
         :required="true"
         headerClasses="justify-content-center"
         modalClasses="modal-lg">
            <template slot="header">
              <h4 class="title title-up">
                {{ txt.modalTitle }}
              </h4>
            </template>
            <template v-if="inProgress" slot="default">
              <el-col class="text-center">
                <h3> {{ txt.inprogressMessage }} </h3>
                <i class="fa fa-cog fa-spin fa-huge fa-fw spin-color"></i>
              </el-col>
            </template>
            <template v-else slot="default">

              <marketplace-follow-strategy-accounts
                v-if="pageIndex == 0 && !bulkFollowDone && !noAccountsAvailable"
                :accounts="accounts"
                :txt="txt"
                :style="inProgress ? 'overflow-y: hidden' : 'overflow-y: auto'"
              />

              <copy-all-trades-confirmation
                v-if="pageIndex == 1 && !bulkFollowDone"
              ></copy-all-trades-confirmation>

              <marketplace-follow-strategy-terms
                v-if="pageIndex == 1 && !bulkFollowDone"
                :txt="txt"
              />
              <marketplace-follow-strategy-bulks
                v-if="bulkFollowDone"
                :txt="txt"
                :acc_list="accountsToFollow"
              />

              <div v-if="noAccountsAvailable" class="row">
                <div class="col-md-12 text-center">
                  <h2> {{ txt.notAvailableForStrategy }}</h2>
                </div>
              </div>

            </template>

            <template slot="footer" v-if="pageIndex == 0 && !noAccountsAvailable">
              <div class="py-3 mx-auto" v-if="modalinprogress">
                <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
              </div>
              <div class="left-side" v-if="modalinprogress == false">
                <p-button type="success" @click.prevent="goNext" link :disabled="accountsToFollow.length == 0">
                  {{ txt.next }}
                </p-button>
              </div>
              <div class="divider" v-if="modalinprogress == false"></div>
              <div class="right-side" v-if="modalinprogress == false">
                <p-button type="danger" @click="doClean" link>
                  {{ txt.cancel }}
                </p-button>
              </div>
            </template>

         <template slot="footer" v-if="pageIndex == 1 && !bulkFollowDone && !noAccountsAvailable">
           <div class="py-3 mx-auto" v-if="modalinprogress">
             <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
           </div>
           <div class="left-side" v-if="modalinprogress == false">
             <p-button type="success" @click.prevent="confirmAddAcc" link :disabled="!changeTermAgree">{{ txt.addaccount }}</p-button>
           </div>
           <div class="divider" v-if="modalinprogress == false"></div>
           <div class="right-side" v-if="modalinprogress == false">
             <p-button type="default" @click.prevent="goBack" link >{{ txt.back }}</p-button>
           </div>
         </template>

         <template slot="footer" v-if="bulkFollowDone" >
            <div class="py-3 mx-auto" v-if="modalinprogress">
             <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
           </div>
           <p-button type="info" @click="toMyAccounts" link v-if="!modalinprogress">
             {{ txt.myaccounts }}
           </p-button>
           <p-button type="success" @click="resetAndClose" link v-if="!modalinprogress">
             {{ txt.done }}
           </p-button>
         </template>

        <template slot="footer" v-if="noAccountsAvailable">
          <p-button type="success" @click="resetAndClose" link v-if="!modalinprogress">
            {{ txt.done }}
          </p-button>
          <p-button @click="closeAndOpenAccount" link v-if="!modalinprogress">
            {{ txt.addaccount }}
          </p-button>
        </template>

  </modal>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Modal from 'src/components/UIComponents/Modal';
import Vue from 'vue';
import {Col, Row} from 'element-ui'
import MarketplaceFollowStrategyAccounts
  from "@/components/Dashboard/Views/Dashboard/Marketplace/FollowStrategy/MarketplaceFollowStrategyAccounts";
import MarketplaceFollowStrategyTerms
  from "@/components/Dashboard/Views/Dashboard/Marketplace/FollowStrategy/MarketplaceFollowStrategyTerms";
import MarketplaceFollowStrategyBulks
  from "@/components/Dashboard/Views/Dashboard/Marketplace/FollowStrategy/MarketplaceFollowStrategyBulks";
import Constants from "@/assets/constants";
import CopyAllTradesConfirmation from "@/components/Dashboard/Views/Dashboard/Accounts/CopyAllTradesConfirmation.vue";

const user_data = JSON.parse(localStorage.getItem('user-info'));
const rules = Constants.methods.getRules()

Vue.use(Col);
Vue.use(Row);

export default {
  name: "MarketplaceFollowStrategy",
  components: {
    CopyAllTradesConfirmation,
    MarketplaceFollowStrategyBulks,
    MarketplaceFollowStrategyTerms,
    MarketplaceFollowStrategyAccounts,
    Modal,
  },
  data() {
    return {
      ueml: "",
      msid: null,
      pageIndex: 0,
      inProgress: true,
      bulkFollowDone: false,
      modalinprogress: false,
      agree: false,
      innerAccounts: [],
      noAccountsAvailable: false,
      acc_list: [],
      txt: {
        parameterstxt: this.$t('changelogpendingparamchange'),
        modalTitle: this.$t('accountstofollow'),
        byfollowingterms: this.$t('byfollowingterms'),
        pricingterms: this.$t('pricingterms'),
        addaccount: this.$t('addaccount'),
        cancel: this.$t('cancel'),
        linktxt: this.$t('linktxt'),
        iagreetxt: this.$t('iagreetxt'),
        balance: this.$t('balance'),
        notavailable: this.$t('notavailable'),
        equity: this.$t('equity'),
        inprogressMessage: this.$t('LookingForAvailableAccounts'),
        done: this.$t('done'),
        somethingwentwrong: this.$t('somethingwentwrong'),
        successFollowed: this.$t('SUCCESS_STR_FOLLOW'),
        next: this.$t('next'),
        back: this.$t('back'),
        notAvailableForStrategy: this.$t('noAccountsAvailableForStrategy'),
        myaccounts: this.$t('myaccounts'),
      },
    }
  },
  computed: {
    ...mapState('marketplaceFollowStrategy', ['showAccModal','accounts', 'accountsToFollow', 'strategy', 'changeTermAgree']),
    ...mapState("copy_all_trades_confirmation", ["copy_all_trades"]),
  },
  methods: {
    ...mapActions('marketplaceFollowStrategy', ['clean', 'setAccounts', 'setStrategy', 'setTerms', 'setAccountToFollow']),
    goNext() {
      this.pageIndex++
    },
    goBack() {
      this.pageIndex--
      this.setTerms(false)
      this.checkStrategy()
    },
    getAccounts() {
      this.$getAllAccounts({ eml: this.ueml })
        .then(this.loadAccounts, this.responseError);
    },
    closeAndOpenAccount() {
      this.resetAndClose()
      this.openAddNewAccount()
    },
    openAddNewAccount() {
      this.$router.push({
        name: 'MyAccounts',
        params: { newAccount: 'true' }
      })
    },
    loadAccounts(resp) {
      if (resp.success) {
        this.innerAccounts = [];
        for (let i=0;i < resp.data.validated.length;i++) {
          const verified = resp.data.validated[i];
          if (verified.strategies === undefined || verified.strategies === null || verified.strategies.length === 0 && !verified.nofollower_failed_login) {
            this.innerAccounts.push(verified);
          }
        }
        this.setStrategy(this.strategy)
        this.innerAccounts.filter( e => this.acc_list.push(e.id) );
        this.setAccounts(this.innerAccounts)
        this.checkStrategy()
        this.inProgress = false;
      }
    },
    bulkFollow(idx) {
      if (idx < this.accountsToFollow.length) {
        let param = {
          copy_all_trades: this.copy_all_trades
        }
        const success_broker_rule = resp => {
          if(resp.success) {
            const success_follow = resp => {
            if (resp.success) {
              this.accountsToFollow[idx]['followSuccess'] = true
              this.accountsToFollow[idx]['msg'] = resp.msg
            } else {
              this.accountsToFollow[idx]['followSuccess'] = false
              this.accountsToFollow[idx]['msg'] = resp.msg
            }
            if (idx === this.accountsToFollow.length - 1) {
              this.updateAccList()
            } else {
              this.bulkFollow(idx + 1)
            }
          }
            const fail_follow = resp => {
            this.accountsToFollow[idx]['followSuccess'] = false
            this.accountsToFollow[idx]['msg'] = this.$t(resp.data.msg)

            if (resp.data.data !== undefined) {
              this.accountsToFollow[idx]['msg'] = this.$t(resp.data.msg, [resp.data.data.currency])
            }

            if (idx === this.accountsToFollow.length - 1) {
              this.updateAccList()
            } else {
              this.bulkFollow(idx + 1)
            }
          }

            this.$delsertFollower(
              this.strategy,
              this.accountsToFollow[idx].id,
              false,
              param
            ).then(success_follow, fail_follow)
          } else {
            fail_broker_rule(resp)
          }

        }
          const fail_broker_rule = resp => {
            this.accountsToFollow[idx]['followSuccess'] = false
            this.accountsToFollow[idx]['msg'] = this.$t(resp.msg)
            if (idx === this.accountsToFollow.length - 1) {
              this.updateAccList()
            } else {
              this.bulkFollow(idx + 1)
            }
          }

        this.$validade_broker_rule(this.accountsToFollow[idx].id).then(success_broker_rule, fail_broker_rule)
      }
    },
    checkStrategy() {
      let param = {
        sid: this.strategy,
        accounts: this.acc_list
      }

      const success = resp => {
        let acc = []
        if ( resp.success ) {
          acc = resp.data.filter( e => e.canFollow === 1)
        }
        if (acc.length === 0) {
          fail(resp)
        }
      }

      const fail = resp => {
        //console.log("fail Bulk Follow Strategy" + JSON.stringify(resp))
        this.inprogress = false
        this.noAccountsAvailable = true
      }

      if(this.acc_list.length == 0){
        return fail()
      }

      this.$canBulkFollowStrategy(param).then(success, fail)
    },
    confirmAddAcc() {
      this.modalinprogress = true
      this.showTerms = false
      this.txt.inprogressMessage = this.$t('bulk_folinprogress')
      this.bulkFollow(0)
    },
    updateAccList() {
      this.innerAccounts = this.accountsToFollow
      this.bulkFollowDone = true
      this.inprogress = false
      this.modalinprogress = false
    },
    doClean() {
      this.resetAndClose()
    },
    resetAndClose() {
      this.pageIndex = 0
      this.bulkFollowDone = false
      this.inProgress = true
      this.noAccountsAvailable = false
      this.txt.inprogressMessage = this.$t('LookingForAvailableAccounts')
      this.setTerms(false)
      this.clean();
    },
    toMyAccounts() {
      this.resetAndClose()
      if(rules.isAdmin) {
        this.$router.push('/admin_accounts/all');
      } else {
        this.$router.push('/accounts/main');
      }
    },
  },
  watch: {
    showAccModal: {
      handler(val) {
        this.ueml = user_data.email;
        if (val === true) {
          this.getAccounts()
        }
      },
      deep: true,
    },
  }
}
</script>
