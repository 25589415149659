<template>
  <div>
    <hr>
    <div class="mb-2">
      <div class="text-center mb-2">
        <b> {{ $t('copy_all_trades_confirmation_txt') }}</b>
      </div>
      <div class="text-center mt-2">
        <p-checkbox v-model="copy_all_trades_model">{{ $t("yestxt") }}</p-checkbox>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'copy-all-trades-confirmation',
  data() {
    return {
      copy_all_trades_model: true
    }
  },
  methods: {
    ...mapActions('copy_all_trades_confirmation', ['setCopyAllTrades'])
  },
  watch: {
    copy_all_trades_model: {
      handler(val) {
        this.setCopyAllTrades(val)
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
//
//hr {
//  margin-top: 0;
//}
//
//.auto-ul li:hover {
//  background: silver;
//  cursor: pointer;
//}
//
//.broker-logo img {
//  border-radius: 50%;
//  width: 35px;
//  border: 1px solid silver;
//}
//
//.card .card-footer .stats .btn-group i {
//  color: #fff !important;
//}
//
//.meine-op-btn:hover i {
//  color: #51bcda !important;
//}
//
//.logo-broker img {
//  border: solid 1px silver;
//  border-radius: 100%;
//  max-width: 35px;
//  max-height: 35px;
//}
//
//@keyframes cooldown {
//  0% {
//    width: 100%;
//  }
//  100% {
//    width: 0;
//  }
//}
//
//button:disabled {
//}
</style>
