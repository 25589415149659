<template>
  <el-row :gutter="10" style="max-height: 400px; overflow-x: hidden;">
    <el-col v-for="acc in acc_list" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
      <el-row :gutter="10">
        <div style="display: inline-block">
          <el-tooltip
            :content="txt.parameterstxt"
            :open-delay="100"
            placement="top">
            <p-button v-if="acc.followSuccess" type="primary" v-on:click="goParameters(acc.id)" link style="margin-top: -15px;">
              <i class="fa fa-gear fa-2x mb-2" style="font-size: 30px;"></i>
            </p-button>
            <p-button v-else disabled type="primary" link style="margin-top: -15px;">
              <i class="fa fa-gear mb-2" style="font-size: 30px;"></i>
            </p-button>
          </el-tooltip>
        </div>
        <div class="broker-logo" style="display: inline-block; float: unset; ">
          <el-avatar :size="45" :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`" />
        </div>
        <div style="display: inline-block;">
          <span class="font-weight-bold" style="font-size: 20px; display: block;">
            {{ acc.account }}
          </span>
          <span class="text-muted" v-if="acc.status.balance">
            <b>{{ txt.balance }}:</b>
            <b class="text-success">
              {{ acc.status.balance.toFixed(2) }}
            </b>
          </span>
          <span class="text-muted" v-if="acc.status.equity">
            <b>{{ txt.equity }}:</b>
            <b :class="acc.status.equity < acc.status.balance ? 'text-danger' : 'text-success'">
              {{ acc.status.equity.toFixed(2) }}
            </b>
          </span>
        </div>
        <div class="col-md-11 alert alert-info" v-if="acc.followSuccess">
          <i class="fa fa-info-circle"></i>
          {{ $t(acc.msg) }}
        </div>
        <div class="col-md-11 alert alert-danger" v-if="!acc.followSuccess">
          <i class="fa fa-info-circle"></i>
          {{ $t(acc.msg) }}
        </div>
        <div class="col-md-6">
        </div>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>


import { Col, Row } from 'element-ui'
import Vue from "vue";
import {mapState} from "vuex";

Vue.use(Col);
Vue.use(Row);

export default {
  name: "MarketplaceFollowStrategyBulks",
  props: {
    txt: {
      type: Object,
    },
    acc_list: {
      type: Array,
    }
  },
  computed: {
    ...mapState('marketplaceFollowStrategy', ['strategy']),
  },
  methods: {
    goParameters(acc_id) {
      window.open("/accounts/parameters/" + acc_id + "/" + this.strategy, '_blank')
    },
  }
}
</script>
