<template>

  <!--<el-row :gutter="20" class="marketplace-card" :style="handleStyle()">

    <el-col v-if="isSuperRank" class="super-rank">
      <div class="badge-super-rank">
        <span class="title-super-rank">SUPERRANK</span>
        <svg class="icon-super-rank" viewBox="0 0 640 512">
          <path d="M406.1 61.65C415.4 63.09 419.4 74.59 412.6 81.41L374.6 118.1L383.6 170.1C384.1 179.5 375.3 186.7 366.7 182.4L320.2 157.9L273.3 182.7C264.7 187 255 179.8 256.4 170.5L265.4 118.4L227.4 81.41C220.6 74.59 224.6 63.09 233.9 61.65L286.2 54.11L309.8 6.332C314.1-2.289 326.3-1.93 330.2 6.332L353.8 54.11L406.1 61.65zM384 256C401.7 256 416 270.3 416 288V480C416 497.7 401.7 512 384 512H256C238.3 512 224 497.7 224 480V288C224 270.3 238.3 256 256 256H384zM160 320C177.7 320 192 334.3 192 352V480C192 497.7 177.7 512 160 512H32C14.33 512 0 497.7 0 480V352C0 334.3 14.33 320 32 320H160zM448 416C448 398.3 462.3 384 480 384H608C625.7 384 640 398.3 640 416V480C640 497.7 625.7 512 608 512H480C462.3 512 448 497.7 448 480V416z"
                id="mainIconPathAttribute"
                fill="#ffffff"/>
          </svg>
      </div>
    </el-col>-->

  <el-card class="card" :class="handleStyle" :body-style="{ 'border-bottom': 'none' }">
    <el-container>
      <el-header class="margin-top">
        <el-row class="card-header-container">
          <el-col :xs="19" :sm="19" :md="19" :lg="19" :xl="19">
            <div class="card-title">
              <div v-if="isLoadingWatching" class="text-center">
                <i class="fa fa-cog fa-spin fa-huge progress-style"></i>
              </div>
              <i v-else-if="card.watchlist" class="fa fa-star text-warning watchlist"
                 v-on:click="delInsertWatchlist(card.watchlist, card.strat_id)"></i>
              <i v-else class="fa fa-star-o watchlist text-warning"
                 v-on:click="delInsertWatchlist(card.watchlist, card.strat_id)"></i>
              <span class="text-overflow">{{ card.strat_name }}</span>
            </div>
          </el-col>
          <el-col class="text-right" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-tooltip v-if="card.nickname !== null" :content="card.nickname" :open-delay="100" placement="top"
                        popper-class="adjust-width">
              <el-avatar :size="50" :src="avatarImage(card.userid, card.profile_updated)" @error="onAvatarError"
                         class="card-image"/>
            </el-tooltip>
            <el-avatar v-else :size="50" :src="avatarImage(card.userid, card.profile_updated)" @error="onAvatarError"
                       class="card-image"/>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="left-container">
            <div class="card-likes-container">
              <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
              <div class="card-capsule fb-background"><span class="stats-info">{{ card.watchlistCount }}</span></div>
              <i class="fa fa-dollar" aria-hidden="true"></i>
              <div class="card-capsule fb-background">
                <el-tooltip :content="$t('performancefee')" :open-delay="100" placement="top"
                            popper-class="adjust-width">
                  <span class="stats-info">{{ card.price * 100 }}%</span>
                </el-tooltip>
              </div>
              <div v-if="adminusr" class="card-capsule-danger fb-background">
                <span v-if="card.type === 'Private'" class="stats-info">PRIVATE</span>
              </div>

              <!--<svg style="height: 26px; width: 20px; margin-left: 10px; margin-top: 4px;">
                <use xlink:href="#icons-medal-1"></use>
              </svg>-->

            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main class="margin-top" style="height: 100%; position: relative">

        <div v-if="isShowMenu" class="menu-container">
          <ul class="marketplace-card-menu">
            <li>
              <div class="marketplace-card-menu-item" @click="sharePublicLink">{{ $t('helper_sharetxt') }}</div>
            </li>
            <li>
              <div class="marketplace-card-menu-item" @click="onOpenInfo(card.strat_id)">{{ $t('infotxt') }}</div>
            </li>
            <!--<li><div class="marketplace-card-menu-item" @click="onRemoveStrategy(card.account, card.strat_id)">Remove from Strategy</div></li>-->
            <!--<li>
              <div class="marketplace-card-menu-item" v-if="isWlAdmin && isWlSession && isPublic">
                <wl-marketplace-switch :sid="card.strat_id" :strategiesInWhitelabel="stratsInWhitelabel"/>
              </div>
            </li>-->
          </ul>
        </div>

        <el-row class="card-body">
          <div class="chart-body" style="display: flex">

            <svg v-if="!card.svg_error" width="100%" height="100%" viewBox="0 0 500 350" fill="none"
                 xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
              <image :xlink:href="getSVGUrl(card.strat_id)" @error="onSVGError"/>
            </svg>
            <svg v-else width="100%" height="100%" viewBox="0 0 500 350" fill="none" xmlns="http://www.w3.org/2000/svg"
                 preserveAspectRatio="xMidYMid meet">
              <image :xlink:href="noChartData"/>
            </svg>

          </div>
        </el-row>
        <el-col>
          <el-row class="margin-top">
            <el-col class="stats-title text-overflow" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">{{ $t('closed_pl') }}
            </el-col>
            <el-col class="stats-title text-center text-overflow" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              {{ $t('max_draw_down') }}
            </el-col>
            <el-col v-if="card.experience_rank" class="stats-title text-right text-overflow" :xs="8" :sm="8" :md="8"
                    :lg="8" :xl="8">{{ $t('expirience_txt') }}
            </el-col>
          </el-row>
          <el-row>
            <el-col class="stats-subtitle text-overflow" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              {{ (card.accumulatedBalance * 100).toFixed(2) }}%
            </el-col>
            <el-col class="stats-subtitle text-center text-overflow" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              {{ (card.maxDrawdown * 100).toFixed(2) }}%
            </el-col>
            <el-col v-if="card.experience_rank" class="stats-subtitle text-right text-overflow" :xs="8" :sm="8" :md="8"
                    :lg="8" :xl="8">{{ handleExperience(card.experience_rank) }}
            </el-col>
          </el-row>
          <el-row class="margin-top">
          </el-row>
        </el-col>
        <el-col class="margin-top">
          <thermometer
              :title="makeTitle(card.risk_score)"
              :value="card.risk_score"
              :max-size="10"
          />
        </el-col>
      </el-main>
      <el-footer class="container-footer">
        <el-col>
          <el-row>
            <el-col :span="20">
              <p-button v-if="!isShowMenu" style="width: 100%" type="info" link @click="openAccModal(card.strat_id)">
                {{ getFollowText }}
              </p-button>
              <p-button type="flat" style="background: none;" v-else disabled></p-button>
            </el-col>
            <el-col :span="2">
              <el-tooltip :content="`${$t('infotxt')}`" placement="top">
                <p-button type="info" link @click="onOpenInfo(card.strat_id)">
                  <i class="fa fa-info"></i>
                </p-button>
              </el-tooltip>
            </el-col>
            <el-col :span="2">
              <el-tooltip :content="`${$t('helper_sharetxt')}`" placement="top">
                <p-button type="warning" link @click="sharePublicLink">
                  <i class="fa fa-share"></i>
                </p-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-footer>
    </el-container>
  </el-card>

  <!--</el-row> -->

</template>

<script>
import Vue from 'vue';
import {Card, Avatar, Col, Row, Footer, Header, Main, Slider, Tooltip, Image, Icon} from 'element-ui'
import Thermometer from "@/components/Dashboard/Views/Components/Thermometer";
import WlMarketplaceSwitch from "src/components/Dashboard/Views/Dashboard/Strategies/wl-marketplace-switch";
import {mapActions} from "vuex";
import Constants from 'src/assets/constants'
import {MD5} from 'crypto-js';

const user_data = JSON.parse(localStorage.getItem('user-info'));
const rules = Constants.methods.getRules();
const timeout = 10;

Vue.use(Card)
Vue.use(Avatar)
Vue.use(Col)
Vue.use(Row)
Vue.use(Footer)
Vue.use(Header)
Vue.use(Main)
Vue.use(Slider)
Vue.use(Tooltip)

export default {
  name: "MarketplaceCard",
  components: {
    Thermometer,
    WlMarketplaceSwitch,
  },
  data() {
    return {
      adminusr: rules.isAdmin,
      isShowMenu: false,
      isLoadingWatching: false,
      userPlaceholder: '/static/img/user_placeholder.png',
      avatarBaseUrl: "https://hoko.ams3.digitaloceanspaces.com/",
      noChartData: '/static/img/empty_chart.svg',
      taskerUrl: Constants.methods.taskerurl(),
      followText: this.$t('followtxt')
    }
  },
  props: {
    card: {
      type: Object,
    },
    stratsInWhitelabel: {
      type: Array,
    },
    isSuperRank: {
      type: Boolean,
    },
    showAvatarPlaceholder: {
      type: Number
    }
  },
  computed: {
    getFollowText() {
      return this.$t('followtxt')
    },
    isHokoAdmin() {
      return Constants.methods.getRules().isAdmin
    },
    isWlAdmin() {
      return Constants.methods.getRules().hasWlRole
    },
    isWlSession() {
      return Constants.methods.getRules().isWhitelabelSession
    },
    isPublic() {
      return this.card.type === 'Public' ? true : false
    }
  },
  methods: {
    ...mapActions('marketplace', ['updateCards']),
    ...mapActions('marketplaceFollowStrategy', ['openAccModal']),
    makeTitle(val) {
      return this.$t('risk_txt') + ": " + val;
    },
    getSVGUrl(strat_id) {
      let url = this.taskerUrl + '/storage/' + strat_id + '_linechart.svg'
      return url
    },
    onSVGError(e) {
      if (e) {
        this.card.svg_error = true
      }
    },
    avatarImage(userid, isUploaded) {
      if (isUploaded && !this.showAvatarPlaceholder) {
        return this.userPlaceholder //this.avatarBaseUrl + 'user_avatar/' + MD5(userid + 'login').toString() + '.png'
      } else {
        return this.userPlaceholder
      }
    },
    onAvatarError(event) {
      console.log(event)
      return event.target.src = this.userPlaceholder
    },
    delInsertWatchlist(isWatching, sid) {
      this.isLoadingWatching = true
      this.$delsertStrategy({isWatching}, {userid: user_data.email, sid: sid}).then(
          (resp) => {
            if (resp.success) {

              if (isWatching === 0) {
                this.addedWatchlist()
              } else {
                this.removedWatchlist(this.card)
              }

            } else {
              this.responseError(resp)
            }
            this.isLoadingWatching = false
          }
      )
    },
    sharePublicLink() {
      window.open('/public/' + this.card.url, "_blanks");
    },
    onOpenInfo(sid) {
      let sidx = sid
      let sname = null;
      let sdesc = null;

      if (sidx >= 0) {
        sname = this.card.strat_name;
        sdesc = this.card.strat_desc;
        let account = this.card.account;

        localStorage.setItem('_tmp-sid-account', JSON.stringify({
          sid: sid, sname: sname, sdesc: sdesc, account: account
        }))

        if (this.isHokoAdmin) {
          window.open("/admin_strategies/info", '_blank');
        } else {
          window.open("/strategies/info", '_blank');
        }
      } else {
        const _tryAgain = n => {
          this.onOpenInfo(sid);
        };
        setTimeout(_tryAgain, timeout * 5);
      }
    },
    onShowMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    handleStyle() {
      return this.isSuperRank ? 'ranking-style' : ''//'background-color: #ffe5d3; padding-top: 25px; border-radius: 6px;' : '';
    },
    removedWatchlist(card) {
      this.updateCards(card)
      this.$toast.clear()
      this.$toast.success(
          this.$t('removewatchlistdone')
      );
    },
    addedWatchlist() {
      this.card.watchlist = 1
      this.$toast.clear()
      this.$toast.success(
          this.$t('addingwatchlistdone')
      );
    },
    handleExperience(rank) {
      switch (rank) {
        case 'RANK_1': {
          return 'Begginer'
          break
        }
        case 'RANK_2': {
          return 'Advanced'
          break
        }
        case 'RANK_3': {
          return 'Proven'
          break
        }
        case 'RANK_4': {
          return 'Veteran'
          break
        }
        case 'RANK_5': {
          return 'Legend'
          break
        }
      }
    },
  }
}
</script>


<style scoped>
</style>
